(function ($) {
  $(function () {

    /*
     * Smooth scroll
     */
    $('a[href^="#"]').on('click', function (e) {
      e.preventDefault();

      var target = this.hash;
      var $target = $(target);

      $('html, body').animate({
        'scrollTop': $target.offset().top - 106
      }, 1000, 'swing');
    });


    /*
     * Navbar fixed
     */
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 1) {
        $('.site-header').addClass('navbar-fixed-top animated fadeInDown');
      } else {
        $('.site-header').removeClass('navbar-fixed-top animated fadeInDown');
      }
    });

    /*
     * Toggle burger menu on mobile
     */
    $('.menu-btn-burger').on('click', function () {
      $('#nav-icon').toggleClass('open-custom-menu');
      $('body').toggleClass('fixedPosition');
      $(".mobile-menu").toggleClass('sliding-open');
    });

    /*
     * Owl carousel
     */
    $('.owl-hero').owlCarousel({
      loop: true,
      margin: 0,
      responsiveClass: true,
      mouseDrag: false,
      touchDrag: false,
      autoplayTimeout: 4500,
      autoplayHoverPause: true,
      animateOut: 'fadeOut',
      autoplay: true,
      autoplaySpeed: 2000,
      nav: true,
      dots: false,
      items: 1,
      navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
    });

    $('.owl-testimonials').owlCarousel({
      loop: true,
      margin: 0,
      responsiveClass: true,
      mouseDrag: false,
      touchDrag: false,
      autoplayTimeout: 4500,
      autoplayHoverPause: true,
      animateOut: 'fadeOut',
      autoplay: true,
      autoplaySpeed: 2000,
      nav: true,
      dots: false,
      items: 1,
      navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
    });


    /*
     *   Home video
     */
    $("body").on("click", ".modalVideo", function (e) {
      e.preventDefault();
      $("#videoHome").modal("show");
    });

    /*
     * Scroll up button
     */
    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        $('.scroll-to-top').addClass('show');
      } else {
        $('.scroll-to-top').removeClass('show');
      }
    });
    $('.scroll-to-top').on("click", function () {
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });

    /*
     * Lightbox gallery
     */
    $('.service-gallery').lightGallery({
      selector: '.gallery-item',
      share: false,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
    });

    /*
     * Service mobile buttn
     */

    $('.service-mobile-btn, .service-mobile-back').on('click', function () {
      $('.service-mobile-content').toggleClass('active');
    })


    /*
     * Counter number
     */
    $('.counter').counterUp({
      delay: 10,
      time: 1500
    });




    $('.dropdown .dropdown-main-link i').on('click', function () {
      $('.submenu').toggleClass('active');
    })

  });
})(jQuery);